name: Rafaël Rozendaal

title_en: Nearnext.com
title_hu: Nearnext.com
year: 2017
specs_en: X 
specs_hu: specs HU

text_en: Since 1999, Rafaël Rozendaal has been using the World Wide Web as his artistic medium. He also creates installations, tapestries, lenticulars, books and lectures. Rozendaal uses websites to distribute moving images, generative algorithms that explore composition and interactivity. Near Next.com deals with the idea of the liquid composition. The work adapts to any size to fill the screen with a slow-moving image that exists in a space between abstract painting and animation. Rozendaal could be described as a quasi-heir to modern Dutch abstraction, and his websites could be regarded as temporally extended, late works of Neoplasticism. His kinetic abstract worlds, existing between GIFs and analog animation, are a code and web-based immersion. In nearnext.com we see eight rectangles, of which two are equal in size, their colour gradients alternately transitioning, at intervals, between yellow and turquoise, or between blue and red, in an endless loop. For Rozendaal, the browser is a canvas, the website a work of art, and the Internet an exhibition space.
text_hu: 1999 óta a világháló Rafaël Rozendaal művészeti médiuma. Emellett installációkat, kárpitszöveteket, lentikuláris nyomatokat, könyveket készít és előadásokat tart. Rozendal weboldalakon osztja meg azokat a mozgóképeket, számítógéppel generált algoritmusokat, amelyekben a kompozíciót és az interaktivitást vizsgálja. A nearnext.com weboldal a folyékony kompozíció elképzelésével foglalkozik. A mű bármilyen mérethez alkalmazkodik; a képernyőt kitölti egy lassan mozgó kép, amely az absztrakt festmény és az animáció közötti térben helyezkedik el. Rozendaal a modern holland absztrakció örökösének tekinthető, honlapjai pedig a neoplaszticizmus időben kiterjesztett, késői művei. A GIF- és az analóg animáció között létező kinetikus absztrakt világai a kód és web-alapú alámerülést testesítik meg. A nearnext.com oldalon nyolc téglalapot, köztük két egyenlő méretűt látunk, melyek folyamatos színátmenetet képeznek a sárga és a türkiz vagy a kék és a piros között váltakozva egy végtelen loopban. Rozendaal számára a böngésző a vászon, a weboldal a műalkotás, az Internet pedig a kiállítótér.
images:
  - rafael1.jpg

bio_en: 1980, lives and works in New York (US)
bio_hu: 1980, New Yorkban (US) él és dolgozik

link: http://nearnext.com
video: 