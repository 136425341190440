import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";
import resources from './translations'

const detectionOptions = {
    order: ['path', 'cookie', 'navigator', 'localStorage', 'subdomain', 'queryString', 'htmlTag'],
    lookupFromPathIndex: 0
}


const newInstance = i18n.createInstance();
    newInstance.use(LanguageDetector)
    newInstance.use(initReactI18next)

    newInstance.init({
        resources,
        detection: detectionOptions,
        fallbackLng: ["en"],

        debug: false
    }).then(() => {
    }).catch((err)=>{
        console.log(err)
    })    

export default newInstance


