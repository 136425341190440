import React from 'react'
import ReactAudioPlayer from 'react-audio-player';
import Close from '../ui/CloseButtonWhite'

export default function EsaterEgg(props) {
    return (
        <div className="easteregg-container">
            <Close classes="menu-button-close-color" handleClick={
                () => {
                    props.history.back()
                    props.handleUnMuteEasterEgg()
                }

            }>Close</Close>
            <img src={`../../../recording/${props.background}`} className="easteregg-background" />
            <div className='easteregg-text'>
                <h2 className='easteregg-title'>{props.code}</h2>
                <div>
                    You found a wormhole. Listen to the voice recording and click on the link to explore!
                </div>
                <a className='easteregg-link' href={props.url} target="_blank">Link</a>
                <ReactAudioPlayer
                    src={`../../../recording/${props.file}`}
                    controls
                    loop
                    autoPlay
                />
                <div className='easteregg-author'>
                    { retunrAuthor(props.code) }
               </div>
            </div>
        </div>

    )
}


function retunrAuthor(code) {
    if (code[0] === 'm') return <div>Metahaven, <i>White Night. Before A Manifesto</i>, 2008</div>
    if (code[0] === 'd') return <div>Bogna Konior, <i>The Dark Forest Theory of the Internet</i>, 2020</div>
    if (code[0] === 'c') return <div>John Perry Barlow, <i>A Declaration of the Independence of Cyberspace</i>, 1996</div>
}