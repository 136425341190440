import React , { Suspense }from 'react'


const About = function() {
  
      return (

          <div className="about-col">
            <div>Spatial Affairs. Worlding  – A tér világlása</div>
            <div>
            A Ludwig Múzeum – Kortárs Művészeti Múzeumban megrendezett <i>Térügyek</i> kiállítás részeként megvalósuló <i>Térügyek: A tér világlása</i> egy felfedezésre váró online környezet, többfelhasználós virtuális kiállítás, amelyet a térben araszoló műalkotások népesítenek be úgy, hogy a net.art es böngésző-alapú digitális műveket reprezentáló mozgó figurák a látogatókat reprezentáló avatárokkal keverednek.            <br></br>
            <br></br>A The Rodina dán posztkritikai designstúdió a tervei alapján készült <i>Térügyek: A tér világlása</i> online környezetét Konrad Zuse <i>Számító Tér</i> (Rechnender Raum, 1969) című könyvében kifejtett elmélete ihlette, amely az univerzumot különálló, élő és fejlődő automaták, sejtekből felépülő számítástechnikai rendszerek lakta térként, pontosabban egy önmagát reprodukáló sejtautomataként írja le. A kiállítás virtuális világát benépesítő művek és látogatók számítástechnikai, biológiai és geológiai testek szervek nélkül, a falak és a padló pedig technikai lények: minden és mindenki ugyanabból a digitális szövetből épül fel.
            <br></br>
            <br></br>Egy ilyen folyamatosan fejlődő ökoszisztémának saját ontológiára van szüksége – mint önkényes, performatív, processzuális, bináris kód alapú, mégis lényegében pluralista virtuális környezet. Széthullanak a szubjektum és a környezet határai; minden létező ágenssé válik, még, ha az anyag közbenjárása nélkül cselekszenek is. A világlás az a folyamat, melynek során a digitális tér eleddig rejtve maradt hajlatai napvilágra kerülnek, és további spekulációkhoz, tézisekhez, történetekhez és játékokhoz vezetnek, amelyek viszont digitálisan született internet-alapú műalkotásokban manifesztálódnak. Az online környezet többrétű, generatív térbeliségét Enrico Boccioletti folyamatosan változó textúrákból felépülő hangtere egészíti ki, amely a merevlemezek elektromágneses sugárzásának, illetve vokális búgásának hangfelvételeiből álló elszórt zajokból épül fel.
            <br></br>
            <br></br>Az online környezet a The Rodina válasza a kurátorok által feltett kérdésekre: Miként lehet egy online kiállítás tartalmát térbeli és reszponzív élménnyé alakítani? Mi van akkor, ha a műalkotásokat avatárok képviselik és találkozni lehet velük a virtuális térben? Mi történik, ha a kiállítás ökoszisztémává válik, és Zuse kifejezésével „számítástechnikai univerzumot” hoz létre? Az egyszerre kiállításként és aktív platformként felfogott környezet egy sor beszélgetés, tárlatvezetés és egyéb esemény helyszíne lesz, mint a <i>Térügyek</i> által felvetett problémák megvitatásának katalizátora.
            <br></br>
            <br></br>Művészek:
            <br></br>Morehshin Allahyari, Besorolás Alatt (Unrated), Petra Cortright, Louise Drulhe, Sam Ghantous, JODI, Sam Lavigne & Tega Brain, Oliver Laric, Jan Robert Leegte, Rosa Menkman, Sascha Pohflepp & Alessia Nigretti & Matthew Lutz, Rafaël Rozendaal
            <br></br>
            <br></br>Design és programozás: <a href="https://therodina.com/">The Rodina</a> (Tereza és Vit Ruller)
            <br></br>Hang: <a href="https://soundcloud.com/spcnvdr">Enrico Boccioletti</a>
            <br></br>Kurátorok: Giulia Bini, Lívia Nolasco-Rózsás
            </div>
            <div className="small">
            A <i>Térügyek: A tér világlása</i> a ZKM | Karlsruhe és az EPFL Pavilions, Swiss Federal Institute of Technology, Lausanne koprodukciója a BEYOND MATTER nemzetközi gyakorlati kutatóprogram keretében. 
            <br></br> 
            </div> 
        </div>
      )
  }
  
  
  export default About