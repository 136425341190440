name: Rosa Menkman

title_en: The BLOB of Im/Possible Images
title_hu: The BLOB of Im/Possible Images
year: 2021
specs_en: X
specs_hu: specs HU

text_en: Imagine you could obtain an 'impossible' image of any object or phenomenon that you think is important, with no limits on spatial, temporal, energy, signal/noise or cost resolutions. What image would you create? (the answer can be a hypothetical image of course!) This was the question I asked every scientist I spoke to during my Arts at CERN/Collide Barcelona residency (2019-2020). As a result of this exploration, I now have a collection of Im/Possible images, that I organise in a low poly rendition of the unquantifiable BLOB of Im/Possible images. Some of these images would have never found their way to our eyes; they would have remained impossible, only to exist in the hypothetical realms outside the BLOB. The BLOB (Binary Large OBject) gives a home to the collection of Im/Possible images that all together illustrate the concept of the impossible image and the relationships between affordance, resolution and compromise.As different Axes of Affordance (X,Y,Z and α) cut the BLOB, they define what is possible to resolve, and what images are compromised, or in other words, will never be rendered. (Rosa Menkman) The BLOB of Im/Possible Images is made in commission for Haus der elektronischen Künste Basel (H3K) and uses the NewArt.city platform. 
text_hu: Képzelje el, hogy hozzájuthat az Ön által fontosnak tartott tárgy vagy jelenség „lehetetlen” képéhez, térbeli, időbeli, energia-kérdéssel, és jelátvitellel, ill. zajjal összefüggő korlátok nélkül. Milyen képet hozna létre? (a válasz természetesen egy feltételezett kép is lehet!) Ez az a kérdés, amit minden tudósnak feltettem, akikkel a CERN/collide Barcelona rezidencia (2019-2020) során beszéltem. A kutatás eredményeképp Lehet/etlen/séges (Im/Possible) képek egész gyűjteményére tettem szert, amit a Lehet/etlen/séges képek számszerűsíthetetlen BLOB egy alacsony poligonszámú kiadásába rendszereztem. A képek közül néhány sohasem talált volna utat a szemünkig, lehetetlen maradt volna, és merő feltételezésként létezne tovább a BLOB-on kívül. A BLOB (Binary Large OBject / bináris nagyméretű tárgy) a Lehet/etlen/séges képek otthona, amik összességükben a lehetlen képek koncepcióját, és az affordancia, a felbontás, és veszélyeztetettség közötti kapcsolatokat illusztrálják. Az affordancia különböző tengelyei (X,Y,Z és α) szelik át a BLOB-ot. Ezek határozzák meg, hogy mi oldható meg, mely képek vannak veszélyben, más szavakkal, melyek nem lesznek soha formába öntve. (Rosa Menkman) The BLOB of Im/Possible Images [Lehet/etlen/séges képek BLOB-ja] a Haus der elektronischen Künste Basel (H3K) megbízásából készült és a NewArt.city platformon hozzáférhető.
images:
  - RM.jpeg

bio_en: 1983, Arnhem (NL) Lives and works in  Amsterdam (NL)
bio_hu: 1983, Arnhem (NL) Amszterdamban (NL) él és dolgozik


link: https://newart.city/show/menkman-blob-of-im-possibilities
video: 