import React from 'react'
import {
  Router as Router,
  Route,
} from "react-router-dom";

import Close from '../ui/CloseButton'

import Content  from './Content'

import BesorolasAlatt from './content/BesorolasAlatt.yml'
import JanRobertLeegte from './content/JanRobertLeegte.yml'
import Jodi from './content/Jodi.yml'
import LouiseDrulhe from './content/LouiseDrulhe.yml'
import MorehshinAllahyari from './content/MorehshinAllahyari.yml'
import OliverLaric from './content/OliverLaric.yml'
import PetraCortright from './content/PetraCortright.yml'
import RafaelRozendaal from './content/RafaelRozendaal.yml'
import RosaMenkman from './content/RosaMenkman.yml'
import SamGhantous from './content/SamGhantous.yml'
import SamLavigneandTegaBrain from './content/SamLavigneandTegaBrain.yml'
import SaschaPohflepp from './content/SaschaPohflepp.yml'


function Artwork (props) {
      return (
        <div className="artwork-container">
            <Route path="*/BesorolasAlatt">
              <Content content={BesorolasAlatt} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/PetraCortright">
              <Content content={PetraCortright} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/JanRobertLeegte">
              <Content content={JanRobertLeegte} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/Jodi">
              <Content content={Jodi} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/LouiseDrulhe">
              <Content content={LouiseDrulhe} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/MorehshinAllahyari">
              <Content content={MorehshinAllahyari} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/OliverLaric">
              <Content content={OliverLaric} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/RafaelRozendaal">
              <Content content={RafaelRozendaal} language={props.language} history={props.history}/>
            </Route>  
            <Route path="*/RosaMenkman">
              <Content content={RosaMenkman} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/SamGhantous">
              <Content content={SamGhantous} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/SamLavigneandTegaBrain">
              <Content content={SamLavigneandTegaBrain} language={props.language} history={props.history}/>
            </Route>  

            <Route path="*/SaschaPohflepp">
              <Content content={SaschaPohflepp} language={props.language} history={props.history}/>
            </Route>  
        </div>
      )
  }
    
export default Artwork 