import React, { Suspense } from "react";

import {
    Router as Router,
    Switch,
    Route,
    Link,
    Redirect,
} from "react-router-dom";

import { createBrowserHistory } from "history";

// 
// App settings
//


// 
// Ui elements and pages
// 

import LandingPage from "./pages/LandingPage";
import MenuButton from "./ui/MenuButton";
import OverlayButton from "./ui/OverlayButton";
import Header from "./Header";
import Footer from "./Footer";
import LiveVideo from "./livevideo/LiveVideo"
import Profile from "./Profile";
import LanguageSwitch from "./ui/LanguageSwitch";
import PrivateRoute from "./PrivateRoute";
import Artwork from "./artworks/Artwork";
import Eastereggs from "./easteregg/EasterEggs";
import ChatBar from "./chat/ChatBar"
import ChatMessages from "./chat/ChatMessages"

//
// Static pages
// 

import Livestream from "./LiveStream";
import About from "./pages/About";
import Privacy from "./pages/Privacy";

// 
// Rooms with 3d
// 

import Scene from "./scenes/Scene";
import Installation from "./scenes/Instalation";

// 
// Utilities 
// 

import {
    uniqueNamesGenerator,
} from "unique-names-generator";

import {adjectives, substantives} from './utils/dictionary'


// 
// Firebase configuration
// 

import firebase from './utils/firebase'
const history = createBrowserHistory();

// 
// I18N 
// 

import i18n from './utils/i18.js'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: i18n.language === 'hu' ? 'hu' : 'en',
            user: null,
            volume: 1,
            isMuted: true,
            login: true,
            displayInstructions: false,
            flash: "",
            chat: true,
            showAbout: false,
            showProfile: false,
            url: "",
            adminName: '',
            isWorkshop: false,
            video: null,

        };
    }

    componentDidMount() {
        i18n.on('languageChanged', (language) => {
            i18n.language !== 'hu' ? language = 'en' : language = 'hu'
            history.push(`/${language}`)
            this.setState({ language: language });
        })

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                this.setState({
                    user: user,
                });

                if (user.displayName === null) {
                    const randomName = uniqueNamesGenerator({
                        dictionaries: [adjectives, substantives],
                        separator: " ",
                        style: "capital",
                    });

                    user.updateProfile({
                        displayName: randomName,
                        photoURL: "https://rewire.ams3.digitaloceanspaces.com/sp/av_" + (Math.floor(Math.random() * 15) + 1)  + ".png",
                    }).then(()=>{
                            this.setState({
                                user: firebase.auth().currentUser,
                                showProfile: false
                            });
                    })
                }

                let dbUser = firebase
                    .database()
                    .ref("users/" + firebase.auth().currentUser.uid);
                dbUser.set({
                    username: firebase.auth().currentUser.displayName,
                });
                dbUser.onDisconnect().remove();
            }
        });

        this.flash = firebase.database().ref("flash/");
        this.flashLisener = this.flash.on("value", (snapshot) => {
            this.setState({ flash: snapshot.val().message });
        });

        this.videoRestart = firebase.database().ref("magicbutton/");
        this.videoRestartListener = this.videoRestart.on("value", (snapshot) => {           
            if (this.state.video) {
                this.state.video.pause();
                this.state.video.currentTime = 0;
                this.state.video.play();
            }
        });

        this.settingsRef = firebase.database().ref("settings/"); 
        this.settingsLisener = this.settingsRef.on('value', (snapshot) => {
            this.setState({isWorkshop:snapshot.val().isWorkshop})
            this.setState({adminName:snapshot.val().adminName})
            if (snapshot.val().isWorkshop == true ){
                console.log('The App is in workshop mode')
                // this.setState({ volume: 0 })
                // this.setState({isMuted: true});
                this.handleMute()
            }
        })

        this.historyUnlisen = history.listen((location, action) => {
            this.setState({ url: location.location.pathname });
        });
    }

    componentWillUnmount() {
        this.flash.off('value', this.flashLisener)
        this.settingsRef.off('value', this.settingsLisener)
        this.videoRestart.off('value', this.videoRestartListener)
        this.historyUnlisen()
    }

    handleInstructions = () => {
        this.setState({ displayInstructions: false });
    };

    handleMute = () => {
        this.state.volume === 0
            ? this.setState({ volume: 1 })
            : this.setState({ volume: 0 });

        this.state.isMuted === false
            ? this.setState({isMuted: true})
            : this.setState({isMuted: false});
    };

    handleMuteEasterEgg = () => {
            this.setState({ volume: 0 });
    };

    handleUnMuteEasterEgg = () => {
            if (this.state.isMuted) {
                this.setState({ volume: 1 });
            }
    };

    updateVideo(video) {
        this.setState({video: video})
    }

    handleChatToggle = () => {
        this.state.chat === false
            ? this.setState({ chat: true })
            : this.setState({ chat: false });
    }

    enterTheApp = () => {
        if (this.state.user) {
            this.setState({ login: true });
            history.push(`/${this.state.language}/scene`);
        } else {
            firebase
                .auth()
                .signInAnonymously()
                .then((user) => {
                    this.setState({
                        login: true,
                        user: user
                    });
                    history.push(`/${this.state.language}/scene`);
                })
                .catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;
                });
        }
    };

    handleUserUpdate = () => {
        this.state.user.reload().then((updatedUser) => {
            this.setState({
                user: firebase.auth().currentUser,
                showProfile: false
            });
        });
    };

    handleProfile = () => {
        this.setState({
            showProfile: !this.state.showProfile
        })
    }

    getVolume = () => {
        return this.state;
    };

    handleOnClickVideo() {
        this.videoRestart.set({message:Date.now()})
    }

    render() {
        return (
            <Router history={history}>
                <div className="main-container">
                    <div className="header-container">
                        {this.state.user && this.state.login ? (
                            <div>
                                <Header
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    url={this.state.url}
                                    flash={this.state.flash}
                                />
                                {/* chat */}
                                {this.state.chat ?
                                    <div className="chat">
                                        <ChatMessages
                                            adminName={this.state.adminName}
                                            user={this.state.user}
                                        />
                                        <ChatBar
                                            user={this.state.user}
                                            adminName={this.state.adminName}
                                            logOut={() => {
                                                firebase.auth().signOut()
                                                this.setState({ user: null })
                                            }
                                            }
                                        />
                                    </div>
                                    : ""}
                                <Footer
                                    language={this.state.language}
                                    volume={this.state.volume}
                                    handleMute={this.handleMute}
                                    handleChatToggle={this.handleChatToggle}
                                    handleProfile={this.handleProfile}
                                    chat={this.state.chat}
                                    user={this.state.user}
                                    logOut={() => {
                                        firebase.auth().signOut();
                                        this.setState({ user: null });
                                        this.setState({ login: false });
                                    }}
                                />
                                
                                {this.state.showProfile ?
                                    <Profile
                                        firebase={firebase}
                                        user={this.state.user}
                                        handleUserUpdate={this.handleUserUpdate}
                                        handleProfile={this.handleProfile}
                                        history={history}
                                    />
                                    : ""
                                }
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    {/* <LiveVideo user={this.state.user} /> */}

                    <MenuButton
                        handleClick={() => this.setState({ showAbout: true })}
                        language={this.state.language}
                    />

                    <LanguageSwitch
                        language={this.state.language}
                        setState={this.setState.bind(this)}
                    />

                    {this.state.showAbout ? (
                        <About
                            setParentState={this.setState.bind(this)}
                            language={this.state.language}
                        />
                    ) : null}

                    <Switch location={location}>
                        <PrivateRoute
                            path={`*/scene`}
                            user={this.state.user}
                            login={this.state.login}
                            language={this.state.language}
                        >
                            { this.state.isWorkshop == true && this.state.user ? 
                                <LiveVideo user={this.state.user} guideName={this.state.adminName}/> : '' 
                            }
                            { this.state.volume ? 
                                <AudioPlayer volume={this.props.volume}/> : ''
                            }
                            { this.state.adminName === this.state.user?.displayName ? 
                                <button className="restartVideo" onClick={this.handleOnClickVideo.bind(this)} >Restart video</button> : ''
                             }
                            <Scene
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                adminName={this.state.adminName}
                                history={history}
                                sceneFile="mainscene.babylon"
                                handleInstructions={this.handleInstructions}
                                handleMute={this.handleMuteEasterEgg}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                                language={this.state.language}
                                updateVideo={this.updateVideo.bind(this)}
                            />

                        </PrivateRoute>
                        <PrivateRoute
                            path={`*/installation`}
                            user={this.state.user}
                            login={this.state.login}
                            language={this.state.language}
                        >
                            <Installation
                                getVolume={this.getVolume}
                                firebase={firebase}
                                user={this.state.user}
                                history={history}
                                sceneFile="next_andras_1.babylon"
                                handleInstructions={this.handleInstructions}
                                displayInstructions={
                                    this.state.displayInstructions
                                }
                                language={this.state.language}
                            />

                        </PrivateRoute>

                        <Route path={`/${this.state.language}/privacy`}>
                            <Privacy />
                        </Route>
                        {/* <Route path={`/${this.state.language}/scene/artwork`}>
                            <Artwork
                                language={this.state.language}
                            />
                        </Route> */}
                        <Route path="*">
                            <Route exact path="/">
                                <Redirect to={`/${this.state.language}`} />
                            </Route>
                            <Route path="/:lang">
                                <LandingPage
                                    language={this.state.language}
                                    handleOnClick={this.enterTheApp}
                                />
                            </Route>
                        </Route>
                    </Switch>
                    <Switch location={location}>
                        <Route path={`/${this.state.language}/scene/artwork`}>
                            <Artwork
                                language={this.state.language}
                                history={history}
                            />
                        </Route>
                        <Route path={`/${this.state.language}/scene/easteregg`}>
                            <Eastereggs
                                language={this.state.language}
                                history={history}
                                handleUnMuteEasterEgg={this.handleUnMuteEasterEgg}
                            />
                        </Route>
                    </Switch>
                </div>
            </Router>
        );
    }
}

export default App;


class AudioPlayer extends React.Component {
    render() {
      return (
        <div>
          <audio ref="audio_tag" src="https://audio.therodina.live/radio/8010/radio.mp3" controls autoPlay />
        </div>
      );
    }
  }

