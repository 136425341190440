import React from "react";
import ReactPlayer from 'react-player'
import { useTranslation } from 'react-i18next';
import i18 from '../utils/i18';

function LandingPage (props) {
    const { t, i18n } = useTranslation('',{i18});

        return (
            <div className="splashScreen-landing">
                {/* <div className="splashScreen-info">
                    <div>Language: { this.props.language } </div>
                    In order to maintain a space in which all individuals feel
                    safe and welcome, visitors are required to sign in on entry.
                    Your email address will not be made public. This website
                    stores data such as cookies to enable important site
                    functionality including analytics, targeting, and
                    personalisation. <a href={`./${this.props.language}/privacy`}>Data storage policy</a>
                    .
                </div> */}
                <div className="splashScreen-name">
                    <img src="./images/header_banner.png" />
                    {/* <div>Spatial Aaffairs Worlding 0.2</div> */}
                </div>
                <div className="auth">
                    <a className="auth-title auth-3d" onClick={props.handleOnClick}>
                        <div>{t('Enter')}</div>
                        {t('Spatial Affairs Worlding')}                      
                    </a>
                    {/* <a className="auth-title" onClick={this.props.handleOnClickAccesible}>
                        Watch the Spatial Affairs on a 2D screen   
                    </a> */}
                </div>
                <div className="splashScreen-help">
                {t('Broswer')}
                In case of a trouble contact support@therodina.com.
                <div className="splashScreen-info">
                    {/* <div>Language: { this.props.language } </div> */}
                    {t('Website')}<br></br> <a href={`./${props.language}/privacy`}>{t('Data Storage Policy')}</a>
                    .
                </div>
                </div>

                <div className="splashScreen-image">
                    {/* <img src="./images/splashscreen.png" /> */}
                    <Player />
                </div>
            </div>
        );
}

export default LandingPage;

function Player(props) {
    return (
        <ReactPlayer
            url='https://player.vimeo.com/external/537585231.m3u8?s=589f1dc64e4357bdf821c755c05d9733a0e98f3e'
            muted={true}
            controls={false}
            playing={true}
            width="100%"
            height="100%"
            className="splashScreen-video"
            loop={true}

        />
    )

}
