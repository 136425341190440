name: Louise Drulhe

title_en: Critcal Atlas of the Internet – Spatial analysis as a tool for socio-political purposes 
title_hu: Critcal Atlas of the Internet – Spatial analysis as a tool for socio-political purposes 
year: 2015
specs_en: specs EN
specs_hu: specs HU

text_en: “The Critical Atlas of the Internet, Louise Drulhe’s project, is a virtual and physical exploration of the Internet space. The implications of our physical actions in ‘real-time’ are not only timeless in ‘cyberspace’, but also constitute for the making of an obscure Internet architecture every time we browse the web. The Atlas itself, functions as an enveloping notebook of Drulhe’s discerning methodology in desiring to represent the geography and architecture of the ‘unseen’ Internet territory. Initially a graphic designer, Drulhe’s practice has meticulously evolved into including cyber-spatial analysis. She yearns to understand the sociological, political and economical issues that appear online, or are exasperated by an online presence – ‘a territory we spend time in without knowing its shape’. The Critical Atlas of the Internet, by being parted between fifteen different hypotheses, sheds light on matters such as the monopolisation of non-physical spaces, the possibility of encumbered networks and the potential forms of the Internet.” – Chloe Stavrou, The Critical Atlas of the Internet, An interview with Louise Drulhe
text_hu: HU text is MISSING
images:
  - LD1.jpg
  - LD2.jpg

bio_en: 1990, Paris (FR), lives and works in Paris (FR) 
bio_hu: 1990, Párizs (FR), Párizsban (FR) él és dolgozik


link: https://louisedrulhe.fr/internet-atlas/
video: 