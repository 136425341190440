name: Jan Robert Leegte

title_en: Repositions 
title_hu: Repositions
year: 2017
specs_en: X
specs_hu: HU specs

text_en: The Repositions works are investigations into the fundamental performative nature of software. Instead of taking the web document as a page to display static content; text, images, gifs, video, etc. the works have the document itself perform and can be read as document performances. Referencing Bruce Nauman’s canonical “Wall-Floor Positions” from 1968, the works have the document take new positions in relation to the viewer and the browser’s window frame. Using random algorithms, the documents are infinitely striking new poses.
text_hu: A Repositions sorozat munkái a szoftverek performatív természetét vizsgálják. Bruce Nauman amerikai művész Wall-Floor Positions című 1968-as performanszára reflektálva, Leegte randomizált algoritmusok alkalmazásával éri el, hogy az oldalak tartalma ne a böngésző kereteit kitöltő statikus helyzetben, hanem mindig új pozíciókat felvéve jelenjen meg a néző előtt. A webhelyek üres, kék, fekete és ezüst felületeket, illetve a Google Térkép hegyeit és óceánjait jelenítik meg. 

images:
  - JRL.jpg

bio_en: 1973, Assen (NL), lives and works in Amsterdam (NL)
bio_hu: 1973, Assen (NL), Amszterdamban (NL) él és dolgozik

link: https://repositionsmountains.work/
video: 