import React from "react";
import ReactPlayer from "react-player";
import Close from './ui/CloseButton'

class LiveStream extends React.Component {
    constructor(props) {
        super(props);
    }
    

    componentDidMount() {
    }

    componentWillUnmount() {
      }

    handleClick() {
        this.props.history.back()
    }  

    render() {
        return (
            <div className="livestream">
                <Close handleClick={this.handleClick.bind(this)} />
                <div className="livestream-title">Spatial Affairs<br />the exhibition</div>
                <div className="livestream-container">
                </div>
            </div>
        );
    }
}

export default LiveStream;
