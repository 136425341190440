name: JODI

title_en: Untitled Game
title_hu: Untitled Game
year: 1999–2002 (emulation 2021)  
specs_en: http://www.c3.hu/events/98/ae98/jodi.html
specs_hu: HU specs 

text_en: CTRL-SPACE is a network game available from, and playable on, the Internet. It is a hacked version of the well-known Quake, using its navigation system, but it looks totally different from the gothic style of the original (to that, they reply that its even own Mother would not be able to recognise it). Not only are the weapons missing, but it is designed in the manner how virtual reality was imagined ten years ago. It functions as a meeting-place in cyberspace, a three-dimensional environment where players can navigate, jump, meet, act and talk with the help of their avatars. All this from a subjective perspective – the player sees only the others, not her/his own avatar. It is for advanced users though, since it begins with a little installation procedure. One needs special software to visit CTRL-SPACE. If you are not familiar with software installation, visit http://www.jodi.org instead.
text_hu: Az 1994-ben alakult JODI művészduó kiemelkedő szerepet játszik a net.artban, majd a szoftverművészetben és a számítógépes játékok művészi átalakításában. 1999 óta foglalkoznak régi videójátékok átalakításával, és 2002 óta élik az úgynevezett „Screen Grab” korszakukat, vagyis úgy készítik videómunkáikat, hogy miközben dolgoznak, videójátékot játszanak vagy programoznak, felveszik a monitoron látható képet. A művészek jellegzetes netesztétikáját idéző komplex tájakat felvonultató Untitled Game 14 átalakított játékból áll. Ezek az A-X, aréna, ctrl-F6, ctrl-9, ctrl-space, E1M1AP, G-R, I-N, M-W, O-O, Q-L, slipgate, spawn, V-Y, melyek a Quake1 forráskódját használják, és a játék MacOS9 és Win95 platformon játszható. Az Untitled Game, a Jodi gyakorlatához hasonlóan, az „online médiumok jellemző anarchiájára” hívja fel a figyelmet és arra, milyen fontos megértenünk a programozás bomlasztó erejét a korai net.art radikalizmusa és politikuma mélyén.

images:
  - J.jpg

bio_en: Joan Heemskerk, 1968, Kaatsheuvel (NL). Dirk Paesmans, 1965, Brussels (BE). They live and work in Dordrecht (NL) 
bio_hu: Joan Heemskerk, 1968, Kaatsheuvel (NL). Dirk Paesmans, 1965, Brussels (BE). Dordrechtben (NL) élnek és dolgoznak


link: https://untitled-game.org/Ctrl-Space/
video: 