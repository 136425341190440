name: Sam Ghantous

title_en: Reflecting Pool
title_hu: Reflecting Pool
year: 2021
specs_en: online version
specs_hu: specs HU

text_en: A co-founder of FOREIGN OBJECTS, a multidisciplinary and itinerant design and research studio, artist and architect Sam Ghantous focuses on architecture’s entanglements with contemporary media and internet culture, through networked images, interactive software, and installed spaces. Originally conceived as an interactive installation, Reflecting Pool –- online, frames human-computer interactions through the reflection as an analog for capturing and representing users’ engagement with interfaces. The pool is distorted and affected through engagement with the users, rendering new impressions on the screen’s surface. The work questions the function of screens as spatializing elements, the production and reduction of distance through the transmission of images, how interfaces collapse networks of dispersed subjects, and the redefinition of collectivity in our technological present.
text_hu: Sam Ghantous művész és építész a FOREIGN OBJECTS multidiszciplináris, utazó tervező- és kutatóstúdió társalapítója. Az építészet és a kortárs média, ill. az internetes kultúra összefonódásával foglalkozik, hálózatos képek, interaktív szoftverek és installált terek kapcsán. Az eredetileg interaktív installációként tervezett Tükröző medence / Reflecting Pool – online az ember és a számítógép interakcióját vizsgálja a tükrözés mint a felhasználó és az interfész kapcsolata rögzítésének és ábrázolásának analógiája révén. A medence torzul, és hatással van rá a felhasználóval való kapcsolat; új lenyomatok jelenek meg a képernyőn. A mű megkérdőjelezi a képernyő mint térképző elem funkcióját, a képek továbbítása által keltett és csökkentett távolságot, illetve azt vizsgálja, hogy az interfészek miként döntik össze a szétszórt alanyok hálózatait, és újradefiniálja a kollektivitást technológiai jelenünkben.
images:
  - SG.jpg


bio_en: 1989, Muscat, Oman (OM). Currently based in Toronto, Canada (CA)
bio_hu: 1989, Maszkat, Omán (OM). Jelenleg Torontóban, Kanadában (CA) él és dolgozik


link: http://www.reflectingpool.online
video: 