import React , { Suspense } from 'react'
import i18n from '../utils/i18.js'


function LanguageSwitch (props) {
      return (
        <div className="language-switch">
            {/* <div>({props.language})</div> */}
            { props.language === 'hu' ? <div onClick={() => i18n.changeLanguage('en')}>En</div> : <div onClick={() => i18n.changeLanguage('hu')}>Hu</div>}
        </div>
      )
  }
  
  
  export default LanguageSwitch