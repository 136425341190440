export const adjectives = [
    "Big",
    "Small",
    "Spatial",
    "New",
    "Infinite",
    "Socio-political",
    "Critical"
]

export const substantives = [
    "Repositions",
    "Mere Spaces",
    "Things",
    "Side",
    "New York",
    "Apartment",
    "CTRLSpace",
    "Internet",
    "Atlas",
    "Spatial Analysis",
    "Tool",
    "Purposes",
    "Sculpture",
    "Garden",
    "Boundaries",
    "Vacuum",
    "Emptiness",
    "Threedscans",
    "Reflecting Pool",
    "Wayfinding",
    "Those Who",
    "Syphoning"
];
