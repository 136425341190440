name: Unrated (Besorolás Alatt)

title_en: Wayfinding
title_hu: Wayfinding
year: 2020
specs_en: 3D audiovisual performative installation
specs_hu: HU –– 3D audiovisual performative installation

text_en: Whether it refers to a stage when the subject of a ratification process hasn’t been allocated to any official classification yet, or a permanent position of actively rejecting tags and categorization, the term ‘unrated’ is associated with a sense of exteriority. The Hungarian-Slovak collective BA – Unrated aims to operate in such an unrated territory and find inspiration through experimenting with electro-acoustic music, field-recordings; separating and rejoining layers of sounds with the use of feedback loops and online streaming. Wayfinding is an inquiry into the transmogrification of banal daily visual and auditory experiences. It consists of de- and reconstructed footages of wide-open space, grass, rock, and sky, recordings of steps, wind, and trot framed in a labyrinth. With delving into such an environment, the participation in one’s own estrangement from one’s actual surroundings and the ingestion in the virtual is being aimed at. At this point deterritorialization is inevitable – the detachment is only amplified by the task to find one’s way through the labyrinth. This work was commissioned by A4 Bratislava.
text_hu: Akár egy olyan szakaszra utal, amikor a jóváhagyási eljárás tárgya még nem kapott hivatalos besorolást, akár a címkék és a kategorizálás aktív elutasításának álláspontjára, a „besorolás alatt” kifejezés a kívüllevőség érzését kelti. A magyar-szlovák BA – Unrated kollektíva célja, hogy egy ilyen besorolás nélküli területen működjön, és az elektroakusztikus zenével való kísérletezésből, a terepfelvételekből merítsen ihletet; a hangrétegek szétválasztása és újra összekapcsolása révén visszacsatolások és online streaming használatával. A Wayfinding [Útkeresés] a banális hétköznapi vizuális és auditív tapasztalatok átalakulásának a vizsgálata. A tágas szabad tér, a fű, a sziklák és az ég dekonstruált és rekonstruált képanyagából, a labirintusba vesző lépések, a szél és a dobbanó léptek felvételeiből áll. Egy ilyen környezetbe való elmerülésnél a saját tényleges környezettől való elidegenedésben való részvétel és a virtuális befogadása a cél. Ezen a ponton a deterritorializáció elkerülhetetlen; az elszakadást csak fokozza a feladat, hogy megtaláljuk az utat a labirintuson át. A munka az A4 Bratislava megrendelésére készült.


bio_en: 
bio_hu: 

images:
  - BA.jpg

link: https://www.google.com
video: 