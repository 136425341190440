import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Instructions from './InstructionsNavigation'
import ClassicInstructions from './Instructions'
import { useTranslation } from 'react-i18next';
import i18 from '../utils/i18';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    top: '100%',
    left: '0%',
    width: '100%',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: 'none',
    color: "car(--sc)",
  },
}))(CircularProgress);

function Loading (props) {
  const { t, i18n } = useTranslation('',{i18});

      return (
          <div className="splashScreen">
            <h1 className="splashScreen-main-title">{t('Loading')}</h1>
            <Instructions />
            <div className="splashScreen-title">
            <BorderLinearProgress size="50" thickness={5}/>
               {/* <img src="./images/loading.jpg"/> */}
            </div>
            <ClassicInstructions />
          </div>
      )
    }   
  export default Loading