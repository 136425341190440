import React , { Suspense } from 'react'
import { useTranslation } from 'react-i18next';
import i18 from '../utils/i18';


function Menu (props) {
  const { t, i18n } = useTranslation('',{i18});
  // console.log(i18n.t('about'))
      return (
        <div className="menu-button" onClick={props.handleClick}>
            {/* <img src="./images/supportcat.jpg" /> */}
            {t('About')}
        </div>
      )
  }
  
  
  export default Menu