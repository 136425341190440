name: Oliver Laric

title_en: Three D Scans
title_hu: Three D Scans
year: 2012
specs_en: specs EN
specs_hu: specs HU

text_en: Oliver Laric’s multimedia practice spans from video to sculpture to websites. Appropriation, circulation, the original and its digital copy are among the themes characterizing a body of work informed by the digital and communication technologies, in which the artist uses a various palette of digital software. Initiated in 2012, Threedscans.com presents a collection of 3D scans that can be downloaded and used without copyright restrictions. The scans are based on pieces from the collections of several international institutions among them Albertina, Kunsthistorisches Museum, and Theater Museum, Vienna, Dépôt des sculptures de la Ville de Paris, Musée Carnavalet, Paris, or Museo Archeologico Nazionale di Firenze. While pointing to Laric’s sculptural work, this digital archive addresses the radical reformulation of ideas of copy and original, aura, heritage, image circulation via the online dimension. Beside the explanation of the story of the original source of the scan, the platform features multiple reinterpretations and remix of the original digital copy.
text_hu: Oliver Laric multimédia gyakorlata a videótól a szobrászaton át a weboldalakig terjed. A kisajátítás, a terjesztés, az eredeti és annak digitális másolata szolgálnak témául a digitális és kommunikációs technológiákból táplálkozó munkáihoz, amelyekben a művész digitális szoftverek egész palettáját beveti. A 2012-ben indított Threedscans.com olyan 3D-ben szkennelt tárgyak gyűjteménye, amelyek szerzői jogi korlátozások nélkül letölthetők és felhasználhatók. A másolatok nemzetközi intézmények gyűjteményének darabjain alapulnak, köztük az Albertina, a Kunsthistorisches Museum és a Bécsi Színházi Múzeum, a Dépôt des sculptures de la Ville de Paris, a párizsi Musée Carnavalet vagy a Museo Archeologico Nazionale di Firenze műtárgyain. A digitális archívum egyszerre testesíti meg Laric szobrászati munkáját, és foglalkozik a másolat és az eredeti, a hatás, az örökség és a képek terjesztése gondolatának radikális átalakításával az online dimenzión keresztül. A beszkennelt kép eredeti forrásának történetéhez fűzött magyarázat mellett a platform az eredeti digitális másolat többszörös értelmezését és remixét nyújtja.
images:
  - OL.jpg

bio_en: 1981, Innsbruck (AT), lives and works in Berlin (DE)  
bio_hu: 1981, Innsbruck (AT), Berlinben (DE) él és dolgozik


link: http://threedscans.com/
video: 