const resources = {
    en: {
      translation: {
        "Enter": "Enter",
        "Spatial Affairs Worlding": "Spatial Affairs Worlding",
        "About": "About",
        "Broswer": "For optimal experience watch on a desktop computer with latest Chrome or Firefox. ",
        "Website": "This website stores data such as cookies to enable important site functionality including analytics, targeting, and personalisation.",
        "Data Storage Policy": "Data Storage Policy",
        "Loading":"Loading assets",
        "How to navigate?":'How to navigate?',
        "In order":'In order to move in space CLICK ON THE 3D ENVIRONMENT FIRST and use WASD or your arrows.',
        "If you are stuck reload the page.":'If you are stuck reload the page.',
        "Click, Drag or Touch to turn the view.":'Click, Drag or Touch to turn the view.',
        "Use ":'Use ',
        "to move":"to move.",
        'mute':'Mute',
        'unmute':'Unmute',
        'Profile':'Profile',
        'Exit':'Exit'
      }
    },
    hu: {
      translation: {
        "Enter": "Belépésa a",
        "Spatial Affairs Worlding": "Spatial Affairs Worldingbe",
        "About": "Rólunk",
        "Broswer": "Optimális környezet: asztali számítógép a Chrome vagy a Firefox legújabb verziójával. ",
        "Website": "Ez a weboldal a működéséhez – elemzéshez, célzáshoz és személyre szabáshoz – szükséges adatokat, pl. sütiket tárol.",
        "Data Storage Policy": "Adattárolási szabályzat",
        "Loading":"Eszközök betöltése",
        'How to navigate?':'Navigáció',
        "In order":'A térben való mozgáshoz ELŐSZÖR KATTINTSON A 3D-KÖRNYEZETRE, és használja a WASD billentyűket vagy a nyilakat!',
        "If you are stuck reload the page.":'Ha lefagy a képernyő, töltse be újra az oldalt!',
        "Click, Drag or Touch to turn the view.":'Klikkeljen rá, húzza vagy érintse meg a tárgyat, ha nézőpontot akar váltani!',
        "Use ":'A mozgáshoz használja/használd a ',
        "to move":'billentyűket vagy a nyilakat. ',
        'mute':'Némítás',
        'unmute':'Unmute',
        'Profile':'Profil',
        'Exit':'Kilépés'
      }
    }
  };

export default resources
