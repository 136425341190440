import React from 'react'
import Close from '../ui/CloseButtonWhite'
import ReactPlayer from 'react-player';

function Content (props) {
    const content = props.content

    return (
        <div>
            <Close classes="menu-button-close-color" handleClick={()=> props.history.back()}>Close</Close>
            <div className="artwork-col-container">
                {content.name === 'Unrated (Besorolás Alatt)' ?
                    <div  className="artwork-link">
                        <a onClick={()=>props.history.push('/installation')} target="_blank">↳  Visit { props.language === 'en' ?  content[`title_en`] : content[`title_hu`]  }
                        </a></div> : 
                        
                        <div  className="artwork-link">
                            <div>
                            { Array.isArray(content['link']) ?
                                <div>
                                    { content['link'].map((link, index)=>{
                                        return <a key={index} href={link} target="_blank">↳  {index}.  Visit { props.language === 'en' ?  content[`title_en`] : content[`title_hu`]  }</a>
                                    })}
                                </div> 
                                :
                                <a href={content['link']} target="_blank">↳  Visit { props.language === 'en' ?  content[`title_en`] : content[`title_hu`]  }</a>   
                            }
                            </div>
                        </div>
                }
                <div className="artwork-column">
                    <div className="artwork-name">{content.name}</div>
                    <div className="artwork-bio">{ props.language === 'en' ?  content[`bio_en`] : content[`bio_hu`]  }</div>
                    { content.video ? 
                        <div>
                                <ReactPlayer
                                    className="artwork-player"
                                    url={content.video}
                                    muted={props.muted}
                                    playing={true}
                                    width="100%"
                                    height="auto"
                                    controls={true}
                                />
                        </div>                     
                        : ''
                    }
                    {content.images?.map((img)=>{ return (
                        <img className="artwork-image" key={img} src={`../../../artworks/${img}`} />
                    )})}
                </div>
                <div className="artwork-column">
                    <div className="artwork-title">{ props.language === 'en' ?  content[`title_en`] : content[`title_hu`]  }</div>
                    <div className="artwork-year">{content.year}</div>
                    <div className="artwork-text">{ props.language === 'en' ?  content[`text_en`] : content[`text_hu`]  }</div>

                </div>
            </div>
            {/* <div>{ props.content.images.map(file=>file + ' ') }</div> */}
        </div>
      )
}

export default Content 