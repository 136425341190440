import React from 'react'
import { useTranslation } from 'react-i18next';
import i18 from '../utils/i18';




function Instructions (props) {
  const { t, i18n } = useTranslation('', { i18 });
  
      return (
        <div className="instructions-container">
            <div>
            {t('Click, Drag or Touch to turn the view.')}<br/>
            {t('Use ')}<span>W</span><span>A</span><span>S</span><span>D</span> or ↑→↓← {t('to move')}.
            </div> 
        </div>

      )
  }
  
  
  export default Instructions