import React, { useState, useEffect } from "react";

import FIREBASE from 'firebase/compat/app';
import firebase from "../utils/firebase"

import classes from "./chat.scss";

class ChatBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            input: "",
            messages: [],
        };
    }

    componentDidMount() {
        this.messages = firebase.database().ref("messages/");
        this.messages.limitToLast(15).on("child_added", (snapshot) => {
            this.setState({
                messages: this.state.messages.concat([
                    {
                        ...snapshot.val(),
                        key: snapshot.key,
                    },
                ]),
            });
        });

    }

    componentWillUnmount() {
        this.messages.off();
    }

    handleChange = (e) => {
        this.setState({ input: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        if (this.state.input.length > 0) {
            this.messages
                .push({
                    name: firebase.auth().currentUser.displayName,
                    photoURL: firebase.auth().currentUser.photoURL,
                    userId: firebase.auth().currentUser.uid,
                    message: this.state.input,
                    timestamp: FIREBASE.database.ServerValue.TIMESTAMP,
                })
                .then(() => {
                    this.setState({
                        input: "",
                    });
                });
        }
    };

    render() {
        return (
                <div className="chat-input">
                    <ChatInput
                        type
                        handleSubmit={this.handleSubmit}
                        handleChange={this.handleChange}
                        value={this.state.input}
                    />
                </div>
        );
    }
}

export default ChatBar;

function ChatInput(props) {
    return (
        <form onSubmit={props.handleSubmit} className="chat-input-form">
            <input
                className="chat-input-field"
                type="text"
                value={props.value}
                onChange={props.handleChange}
                maxLength="255"
            />
            <input className="chat-input-submit button" type="submit" value="" />
        </form>
    );

}
