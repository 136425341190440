name: Morehshin Allahyari

title_en: In Mere Spaces All Things Are Side By Side I
title_hu: In Mere Spaces All Things Are Side By Side I
year: 2014
specs_en: HD video with audio
specs_hu: HD video with audio

text_en: Iranian-Kurdish media artist, activist, and writer Morehshin Allahyari, uses computer modeling, 3D scanning, and digital fabrication techniques to explore the intersection of art and activism, inspired by concepts of collective archiving and cultural contradiction in our technologically mediated landscape. In Mere Spaces All Things Are Side By Side explores the complex adoption and accessibility of the internet in the Global South. The piece is inspired by the artist’s Yahoo chat histories from a 4-year long online relationship when Allahyari lived in Iran. The rendered walls of a suspended neutral flat, a fuzzy and intermittent soundscape and fragments of the chats, create an imagined space between physical and virtual, which alludes to the discontinuity, and unstable intermittence of a relationship, and connects its failure to the failure of technology and communication. Rather than thinking about technology from a position of privilege, the work reflects on the limitation, and the frustration of access through digital platforms.
text_hu: Az iráni-kurd médiaművész, aktivista és író, Morehshin Allahyari számítógépes modellezéssel, 3D-s szkenneléssel és digitális technikákkal fedezi fel a művészet és az aktivizmus köztes terét, a közösségi archiválás és a technológia által közvetített táj kulturális ellentmondásainak fogalmából kiindulva. Az In Mere Spaces All Things Are Side By Side az internet komplex alkalmazását és elérhetőségét kutatja a harmadik világban. A művet egy négyéves online kapcsolat során folytatott yahoos csevegések inspirálták, amikor Allahyari Iránban élt. A semleges lakás lebegő renderelt falai, az összemosódó és szaggatott hangok és a beszélgetések töredékei egy képzeletbeli teret hoznak létre a fizikai és a virtuális világ között, ami a kapcsolatok megszakadására és instabilitására utal, és azok kudarcát a technológia és a kommunikáció kudarcával kapcsolja össze. A mű korántsem a kiváltságosok helyzete felől közelíti meg a technológiát, inkább a digitális platformokon keresztüli hozzáférés korlátaira és az a fölött érzett csalódottságra reflektál.
images:
  - MA.jpg

bio_en: Persian - موره شین اللهیاری‎; 1985, Tehran (IR). Lives and works in New York (US)
bio_hu: Perzsául - موره شین اللهیاری‎; 1985, Teherán (IR). New Yorkban (US) él és dolgozik


link: 
video: https://player.vimeo.com/external/538409100.m3u8?s=4bb8e5ce451e364e77ac017891c9f214557bf07e