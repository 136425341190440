import React from 'react'
import { useTranslation } from 'react-i18next';
import i18 from '../utils/i18';


function Instructions(props) {
  const { t, i18n } = useTranslation('', { i18 });
  return (
    <div className="instructions-container-secondary">
      <div className="instructions-block">
        <div>{t('How to navigate?')}</div><br></br>
        <div>
          {t('In order')}<br /><br />

                    {t('If you are stuck reload the page.')}
                </div>
      </div>
    </div>
  )
}


export default Instructions