import React , { Suspense } from 'react'
import {
  Router as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";


function OverlayButton (props) {
      return (
        <div className="overlay-button">
          <Link to={`/${props.language}/scene/artwork/BesorolasAlatt`}>Besorolás Alatt</Link>
            <Link to={`/${props.language}/scene/artwork/JanRobertLeegte`}>Jan Robert Leegte</Link>
            <Link to={`/${props.language}/scene/artwork/jodi`}>JODI</Link>
            <Link to={`/${props.language}/scene/artwork/LouiseDrulhe`}>Louise Drulhe</Link>
            <Link to={`/${props.language}/scene/artwork/MorehshinAllahyari`}>Morehshin Allahyari</Link>
            <Link to={`/${props.language}/scene/artwork/OliverLaric`}>Oliver Laric</Link>
            <Link to={`/${props.language}/scene/artwork/PetraCortright`}>Petra Cortright</Link>
            <Link to={`/${props.language}/scene/artwork/RafaelRozendaal`}>Rafael Rozendaal</Link>
            <Link to={`/${props.language}/scene/artwork/RosaMenkman`}>Rosa Menkman</Link>
            <Link to={`/${props.language}/scene/artwork/SamGhantous`}>Sam Ghantous</Link>
            <Link to={`/${props.language}/scene/artwork/SamLavigneandTegaBrain`}>Sam Lavigne and Tega Brain</Link>
            <Link to={`/${props.language}/scene/artwork/SaschaPohflepp`}>Sascha Pohflepp with Alessia Nigretti and Matthew Lutz</Link>
            <Link to={`/${props.language}/installation`}>Wayfinding</Link>
        </div>
      )
  }
  
  
  export default OverlayButton 