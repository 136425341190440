import React , { Suspense }from 'react'
import Close from '../ui/CloseButton'

import About_EN from './About_En.jsx'
import About_HU from './About_HU.jsx'


const About = function(props) {
  
    const handleClick = () => {
      props.setParentState({showAbout: false})
    }
  
 
      return (
        <div className="about">
          <Close handleClick={handleClick.bind(this)} classes={"menu-button-close menu-left"}/>
          { props.language !== 'hu' ? <About_EN /> : <About_HU /> }


        </div>
      )
  }
  
  
  export default About