import React, {Component} from 'react';
import Room from './Room'
const { connect } = require('twilio-video');


const TOKENENDPOINT = "https://btm-token-service-6416-dev.twil.io/token"

export default class LiveVideo extends Component {
    constructor(props) {
        super(props)

        console.log(props.user)
    
        this.state = {
          identity: props.user.displayName,
          guideName: props.guideName,
          room: null
        }

        this.inputRef = React.createRef();

        this.joinRoom = this.joinRoom.bind(this);
        this.returnToLobby = this.returnToLobby.bind(this);
        this.updateIdentity = this.updateIdentity.bind(this);
        this.removePlaceholderText = this.removePlaceholderText.bind(this);
      }
    
      componentDidMount() {
          this.joinRoom()
      }


      
    async joinRoom() {
        try {
          const response = await fetch(`${TOKENENDPOINT}?identity=${this.state.identity}`);
          const data = await response.json();
          const room = await connect(data.accessToken, {
            name: 'BTM room',
            audio: this.props.guideName === this.props.user.displayName,
            video: this.props.guideName === this.props.user.displayName
          });
      
          this.setState({ room: room });
        } catch(err) {
          console.log(err);
        }
    }
    
    returnToLobby() {
        this.setState({ room: null });
    }

    updateIdentity(event) {
        this.setState({
          identity: event.target.value
        });
      }
    
    removePlaceholderText() {
        this.inputRef.current.placeholder = '';
    }

    render() {
        const disabled = this.state.identity === '' ? true : false;

        return (
          <div className="liveVideo">
            { 
              this.state.room === null
              ? <div className="lobby">
                  Connecting to video
                   {/* <input 
                    value={this.state.identity} 
                    onChange={this.updateIdentity} 
                    ref={this.inputRef} 
                    onClick={this.removePlaceholderText} 
                    placeholder="What's your name?"/>
                  <button disabled={disabled} onClick={this.joinRoom}>Join Room</button> */}
                </div>
              : <Room returnToLobby={this.returnToLobby} room={this.state.room} guideName={this.state.guideName}/>
            }
          </div>
        );
      }
      
}
