import React, { Suspense } from "react";
import { Link } from "react-router-dom";
// const Status = React.lazy(() => import('./Status'));

class Header extends React.Component {
    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        var lastURLSegment = this.props.url;

        return (
            <div>
                <div className="header">
                    <div className="flashmessage-container">
                        { typeof(this.props.flash) == 'string' && (
                            <div className="flashmessage">{this.props.flash}</div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;

function IsActive(name, url) {
    if (name === url) return "nav-button nav-button-is-active";
    else return "nav-button";
}

function RoomName(props) {
    return (
        <div>
            {props.name}
            {props.isLive ? <span className="nav-is-live"></span> : ''}
        </div>
    )
}
