import React, { Component } from "react";
import { LocalParticipant } from "twilio-video";
import Participant from "./Participant";

class Room extends Component {
  constructor(props) {
    super(props);

    this.state = {
      remoteParticipants: Array.from(this.props.room.participants.values()),
    };

    this.leaveRoom = this.leaveRoom.bind(this);
  }

  componentDidMount() {
    // Add event listeners for future remote participants coming or going
    this.props.room.on("participantConnected", (participant) =>
      this.addParticipant(participant)
    );
    this.props.room.on("participantDisconnected", (participant) =>
      this.removeParticipant(participant)
    );

    window.addEventListener("beforeunload", this.leaveRoom);
  }
  componentWillUnmount() {
    this.leaveRoom();
  }

  addParticipant(participant) {
    console.log(`${participant.identity} has joined the room.`);

    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  removeParticipant(participant) {
    console.log(`${participant.identity} has left the room`);

    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
  }

  leaveRoom() {
    this.props.room.disconnect();
    this.props.returnToLobby();
  }

  render() {
    return (
      <div>
        <div className="room">
            <div className="participants">
              { 
                this.props.room.localParticipant.identity === this.props.guideName ?
                <Participant
                  key={this.props.room.localParticipant.identity}
                  localParticipant="true"
                  participant={this.props.room.localParticipant}
                /> : <div></div>
              }
              {this.state.remoteParticipants.map((participant) => {
                return (

                  participant.identity === this.props.guideName ? <Participant
                  key={participant.identity}
                  participant={participant}
                  />
                  : <div key={participant.identity} ></div>
                )
              }
              
              )}
            </div>

          {/* <button id="leaveRoom" onClick={this.leaveRoom}>
            Leave Room
          </button> */}
        </div>
      </div>
    );
  }
}

export default Room;
