name: Petra Cortright

title_en: The infinite sculpture garden without the boundaries torn and ripped into the vacuum of emptiness 
title_hu: The infinite sculpture garden without the boundaries torn and ripped into the vacuum of emptiness 
year: 2010
specs_en: X
specs_hu: specs HU

text_en: Pertra Cortright’s multifaceted artistic practice involves creating and manipulating digital files. A notable member of what became known as the ‘Post Internet’ art movement of the mid- to late-2000s, Cortright’s practice is characterised by a singular artistic reflection of contemporary visual culture. The early online work Infinite sculpture garden presents a sequence of unfolding digital images; a “promenade” through manipulated landscapes and layered marble-like surfaces, leading through a pristine and timeless Arcadia where silhouettes of ancient sculptures blend with the liquid and synthetic aesthetic of the virtual. “Without the boundaries torn and ripped into the vacuum of emptiness”, the Infinite sculpture garden precipitates the user into a potentially endless digital idyll, permeated by that “disinterested delight” that artist Paul Chan saw in Cortright’s work when, with her work VVEBCAM, she appeared “feral, unexpected and unclassified” (Bruce Sterling) in the field of net.art and digital aesthetics and redefined both.
text_hu: Pertra Cortright sokoldalú művészi gyakorlatához hozzátartozik a digitális fájlok létrehozása és manipulálása. A 2000-es évek második felének „Post Internet” néven ismert művészeti mozgalma kiemelkedő tagjaként Cortright gyakorlatát a kortárs vizuális kultúra egyedi művészi tükrözése jellemzi. Cortright korai online műve, a Végtelen szoborkert digitális képek sorozata, egy „sétány” manipulált tájakon és márványszerű felületeken át, az érintetlen és időtlen Árkádián keresztül, ahol ősi szobrok sziluettjei keverednek a virtualitás folyékony és szintetikus esztétikájával. A Végtelen szoborkert „a határok lebontása és ürességbe taszítása nélkül” veti egy potenciálisan végtelen digitális idillbe a felhasználót, amelyet áthat az az „érdek nélküli tetszés”, amelyet a művész, Paul Chan látott Cortright munkájában, amikor VVEBCAM című művével „vadul, váratlanul és besorolhatatlanul” (Bruce Sterling) feltűnt a net.art és a digitális esztétika világában, és újraírta mindkettőt.

images:
  - PC.jpg

bio_en: 1986, Santa Barbara (US), lives and works in Los Angeles (US) 
bio_hu: 1986, Santa Barbara (US), Los Angelesben (US) él és dolgozik


link: https://www.petracortright.com/the_infinite_sculpture_garden/the_infinite_sculpture_garden.html

video: 