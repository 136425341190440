import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/database";
import "firebase/compat/storage";



// import settings from '../../settings.json'
const settings = {
    "colyseusUrl": "wss://pb-server-vjcjq.ondigitalocean.app",
    "apiKey": "AIzaSyBkJnCA5lMb6nCJBJAZ5i1HiT8raJBFT_w",
    "authDomain": "spatialaffairs.beyondmatter.eu",
    "databaseURL": "https://spatialaffairs-default-rtdb.europe-west1.firebasedatabase.app/",
    "storageBucket": "gs://spatialaffairs.appspot.com",
}

const config = {
    apiKey: settings.apiKey,
    authDomain: settings.authDomain,
    databaseURL: settings.databaseURL,
    storageBucket: settings.storageBucket,
};
 
export default firebase.initializeApp(config) 