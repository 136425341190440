import React from 'react'
import {
  Router as Router,
  Route,
} from "react-router-dom";


import EasterEgg from './easteregg';

function Eastereggs (props) {  
      return (
        <>                
          <Route path="*/DFT_6">
            <EasterEgg {...props} file="C01-Cyberspace-C.aac" background="background-01.svg" code="c01" url="https://www.eff.org/de/cyberspace-independence"/>
          </Route>
          <Route path="*/c02">
            <EasterEgg {...props} file="C02-Cyberspace-C.aac" background="background-01.svg" code="c02" url="https://www.eff.org/de/cyberspace-independence"/>
          </Route>

          <Route path="*/d01">
            <EasterEgg {...props} file="D01-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d01" url="https://newart.city/show/phygital-2"/>
          </Route>
          <Route path="*/d02">
            <EasterEgg {...props} file="D02-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d02" url="https://newart.city/show/phygital-2"/>
          </Route>
          <Route path="*/d03">
            <EasterEgg {...props} file="D03-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d03" url="https://newart.city/show/phygital-2"/>
          </Route>
          <Route path="*/d04">
            <EasterEgg {...props} file="D04-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d04" url="https://newart.city/show/phygital-2"/>
          </Route>
          <Route path="*/d05">
            <EasterEgg {...props} file="D05-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d05" url="https://newart.city/show/phygital-2"/>
          </Route>
          <Route path="*/d06">
            <EasterEgg {...props} file="D06-Internet_is_a_Dark_Forest.aac" background="background-02.svg" code="d06" url="https://newart.city/show/phygital-2"/>
          </Route>

          <Route path="*/m01">
            <EasterEgg {...props} file="M01-Metaheaven_B.aac" background="background-03.svg" code="m01" url="https://www.vdrome.org/metahaven/"/>
          </Route>
          <Route path="*/m02">
            <EasterEgg {...props} file="M02-Metaheaven_B.aac" background="background-03.svg" code="m02" url="https://www.vdrome.org/metahaven/"/>
          </Route>
          <Route path="*/m03">
            <EasterEgg {...props} file="M03-Metaheaven_B.aac" background="background-03.svg" code="m03" url="https://www.vdrome.org/metahaven/"/>
          </Route>
          <Route path="*/m04">
            <EasterEgg {...props} file="M04-Metaheaven_B.aac" background="background-03.svg" code="m04" url="https://www.vdrome.org/metahaven/"/>
          </Route>
          <Route path="*/m05">
            <EasterEgg {...props} file="M05-Metaheaven_B.aac" background="background-03.svg" code="m05" url="https://www.vdrome.org/metahaven/"/>
          </Route>
          <Route path="*/m06">
            <EasterEgg {...props} file="M06-Metaheaven_B.aac" background="background-03.svg" code="m06" url="https://www.vdrome.org/metahaven/"/>
          </Route>
        </>
      )
  }
    
export default Eastereggs