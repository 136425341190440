name: Sam Lavigne and Tega Brain

title_en: New York Apartment
title_hu: New York Apartment
year: 2020
specs_en: specs EN
specs_hu: specs HU

text_en: Sam Lavigne and Tega Brain’s project New York Apartment, combines the totality of New York real estate into a single website, covering more than 300 million square feet and spanning the five boroughs. Compiled from actual online real estate listings, the artwork collapses the high and low ends of the market, architectural periods and styles, and neighborhoods and affordability into a single space that cumulatively creates a portrait of New York's living spaces and the real estate market. The four 3D virtual tours (maze, highrise, tower and pyramid) lead through infinite vertical towers, a flat landscape, and a pyramid, geometries generated by extruding the floorplans of every online apartment listing. The apartments, rendered as a nightmarish fictional totality allude to the opacity of the real estate market and advertising. Echoing Dan Graham’s work Homes for America, the project reflects the artists’ interest in revealing, through online interventions, systems for obfuscating data, digital networks, the frequently opaque political and economic conditions shaping computational technologies and their impact on ecological relations, infrastructures and circulation. – Commissioned by the Whitney Museum of American Art for its artport website.
text_hu: Sam Lavigne és Tega Brain New York Apartment című projektje az összes New York-i ingatlant hozza össze egyetlen weboldalon, amely több mint 28 ezer négyzetméteren és öt városrészben található. Az aktuális online ingatlanlista alapján összeállított mű egyetlen térbe sűríti a piac legnagyobb és legkisebb értékű tételeit, az építészeti korszakokat és stílusokat, városrészeket és a megfizethetőséget, amelyből kumulatív módon összeáll New York lakótereinek és az ingatlanpiacnak a portréja. A négy 3D-s virtuális túra (labirintus, felhőkarcoló, torony és piramis) végtelen számú függőleges tornyon, egy sík tájon és egy piramison vezet végig, olyan geometriákon, melyeket az online lakáslisták alaprajzainak összepréselése hozott létre. A rémálomba illő fiktív totalitásként megjelenített lakások az ingatlanpiac és a hirdetések átláthatatlanságát idézik. Dan Graham Homes for America című munkájára utalva a projekt a művészeknek azt a szándékát tükrözi, hogy online beavatkozásukkal leleplezzék az adatok elfedésére szolgáló rendszereket, digitális hálózatokat, a számítástechnikát alakító, gyakran átláthatatlan politikai és gazdasági körülményeket, valamint ezeknek az ökológiai viszonyokra, infrastruktúrára és pénzforgalomra gyakorolt hatását. – A Whitney Museum of American Art megrendelésére az artport weboldalán.

images:
  - SLTB.jpg


bio_en: 1981, San Francisco (US) lives and works in Brooklyn, New York (US). 1982, Sydney (AU) lives and works in Brooklyn, New York (US) 
bio_hu: 1981, San Francisco (US) Brooklynban, New Yorkban (US) él és dolgozik. 1982, Sydney (AU) Brooklynban, New Yorkban (US) él és dolgozik


link: 
  - https://whitney.org/artport-commissions/new-york-apartment/tour/?scene=fattower
  - https://whitney.org/artport-commissions/new-york-apartment/tour/?scene=flat
  - https://whitney.org/artport-commissions/new-york-apartment/tour/?scene=tower
  - https://whitney.org/artport-commissions/new-york-apartment/tour/?scene=pyramid
video: 