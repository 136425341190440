import React , { Suspense }from 'react'

const About = function(props) {
    return (
        <div className="about-col">
            <div>Spatial Affairs. Worlding  – A tér világlása</div>
            <div>
            Conceived as part of the exhibition <i>Spatial Affairs</i>, presented at the Ludwig Museum – Museum of Contemporary Art, Budapest, <i>Spatial Affairs: Worlding</i> is an exploratory online environment, a virtual multi-user exhibition populated by crawling artworks, where visitors’ avatars coexist with moving bodies of internet art and browser-based projects.
            <br></br>
            <br></br>Designed by post-critical Dutch design studio The Rodina, <i>Spatial Affairs: Worlding</i> is inspired by Konrad Zuse's theory of <i>Calculating Space</i> (1969)—describing a universe that consists of an abundance of living and evolving automata, of discrete computational systems composed of cells that add up to a large self-reproducing cellular automaton. The artworks and visitors that inhabit the virtual world of the exhibition are computational, biological, and geological bodies without organs, and the walls and floors are technical beings: everyone and everything is sculpted from the same digital tissue.
            <br></br>
            <br></br>Such an ever-evolving ecosystem requires its own ontology as arbitrary, performative, and processual, as a binary-code-based yet essentially pluralistic virtual environment. Boundaries between subject and surroundings are coming apart; all entities become agents even if they act without the agency of matter. <i>Worlding</i> refers to a constant process of bringing to light as yet undisclosed folds in digital space that lead to further speculations, theses, stories, and games, which in turn manifest as born-digital internet-based works of art. The environment’s multiple, generative spatiality is enhanced by artist Enrico Boccioletti’s organic soundscape of ever-changing textures, composed out of scatters created from recordings of hard drives’ electromagnetic radiation and vocal humming.
            <br></br>
            <br></br>The environment is The Rodina’s answer to questions posed by the curators on how the content of an online exhibition can be transformed into a spatial and responsive experience: What if artworks are represented by avatars and one can meet them in virtual space? What happens when exhibition becomes ecosystem, generating what Zuse terms a “computing cosmos”? Conceived as an exhibition and an active platform, the environment will host a series of talks, guided tours, and events, acting as a catalyzer for discussions around the issues raised by <i>Spatial Affairs</i>.
            <br></br>
            <br></br>ARTISTS
            <br></br>Morehshin Allahyari, Besorolás Alatt (Unrated), Petra Cortright, Louise Drulhe, Sam Ghantous, JODI, Sam Lavigne & Tega Brain, Oliver Laric, Jan Robert Leegte, Rosa Menkman, Sascha Pohflepp & Alessia Nigretti & Matthew Lutz, Rafaël Rozendaal
            <br></br>
            <br></br>Design and programming: <a href="https://therodina.com/">The Rodina</a> (Tereza and Vit Ruller)
            <br></br>Soundscape: <a href="https://soundcloud.com/spcnvdr">Enrico Boccioletti</a>
            <br></br>Curators: Giulia Bini, Lívia Nolasco-Rózsás
            </div>
            <div className="small">
            <i>Spatial Affairs: Worlding</i> is coproduced by ZKM | Karlsruhe and EPFL Pavilions, Swiss Federal Institute of Technology, Lausanne, in the framework of the international practice-based research project BEYOND MATTER. <br></br> 
 
            </div>
          </div>  
    )
}


export default About