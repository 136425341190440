import React, { Suspense } from "react";
import { Link } from "react-router-dom";
import Switch from "@material-ui/core/Switch";
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import i18 from './utils/i18';

 function Header(props) {
    const { t, i18n } = useTranslation('',{i18});
     
        return (
            <div>
                <div className="footer-left">
                    <div
                        className="footer-mute"
                        onClick={props.handleMute}
                    >
                        {props.volume === 0 ? t('unmute') : t('mute')}
                    </div>
                    <div className="chat-status">
                        <span className='footer-button' onClick={() => props.handleProfile()}>
                            Profile
                        </span>

                        <a className="footer-button" onClick={props.logOut}>
                            {t('Exit')}
                        </a>
                        {props.user && ( 
                            <div className="chat-status-profile">
                                <span> {props.user.displayName}</span>
                                <img src={props.user.photoURL} />
                            </div>
                        )}
                    </div>
                    <div
                        className="chat-togle"
                    >
                                <IOSSwitch
                                    checked={props.chat}
                                    onChange={props.handleChatToggle}
                                    name="checkedB"
                                />

                        Chat
                    </div>
                </div>
            </div>
        );
}

const IOSSwitch = withStyles((theme) => ({
    root: {
        width: '3.9em',
        height: '1.87em',
        padding: 0,
        margin: '0',
        marginRight: '0.5em',
    },
    switchBase: {
        padding: 1,
        opacity: 0.5,
        "&$checked": {
            transform: "translateX(1.7em)",
            color: 'var(--tc)',
            opacity: 1,
            "& + $track": {
                backgroundColor: "var(--sc)",
                opacity: 1,
                border: "var(--sc)",
            },
        },
        "&$focusVisible $thumb": {
            color: "var(--sc)",
            border: "6px solid #fff",
            backgroundColor: "var(--sc)",
        },
    },
    thumb: {
        width: '1.5em',
        height: '1.5em',
    },
    track: {
        borderRadius: '5em',
        border: `0px solid ${theme.palette.grey[400]}`,
        boxShadow: 'inset 0px 0px 10px rgba(255,255,255,0.5)',
        backgroundColor: 'rgba(255,255,255,0.1)',
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

export default Header;
