name: Sascha Pohflepp with Alessia Nigretti and Matthew Lut

title_en: Those Who
title_hu: Those Who
year: 2019
specs_en: X
specs_hu: specs HU

text_en: Those Who is based on the field research that Sascha Pohflepp conducted at the State Darwin Museum in Moscow, which is one of the first museum displays in Europe to examine the theory of evolution. The collection of diverse organisms represented in the museum and its stores prompted Pohflepp to reflect on evolution’s historical pathway toward a high-tech future. Inspired by the collection, Pohflepp created an imaginary extension to the Darwin Museum; a panorama of the biosphere generated by genetic engineering and artificial intelligence. The installation at Garage and its online version comprise self-generating video content produced by an artificial neural network, taught by the artist using the materials he studied at the Darwin Museum. Pohflepp’s work proposes a model of a future world, the appearance of which is shaped by technology and artificial intelligence imbued with some level of independence. Pohflepp’s final work was commissioned for the exhibition The Coming World; Ecology as the New Politics 2030–2100 at Garage Museum of Contemporary Art as part of Garage Digital program. The digital version, developed in collaboration with Matthew Lutz and Alessia Nigretti, is available via Garage Digital. Collaborators – Alessia Nigretti and Matthew Lutz
text_hu: A Those Who [Azok, akik] azon a terepkutatáson alapul, amelyet Sascha Pohflepp a moszkvai Állami Darwin Múzeumban végzett – ez az egyik első múzeumi tárlat Európában, amely az evolúcióelméletet vizsgálja. A múzeumban és tárlóiban bemutatott különféle organizmusok gyűjteménye hatására Pohflepp egy environmentet hozott létre, melyben elképzeli az evolúció high-tech jövőbe vezető történelmi útját. A gyűjtemény inspirációjára Pohflepp képzeletben kiterjesztette a Darwin Múzeum kiállítását a génsebészet és a mesterséges intelligencia segítségével létrehozott bioszféra panorámája révén. A Garage installációja és annak online változata egy önállóan generálódó videótartalom, amelyet egy mesterséges ideghálózat hoz létre, melyet a művész tanított meg a Darwin Múzeumban tanulmányozott anyagok felhasználásával. Pohflepp munkája egy jövőbeli világ modelljét vetíti előre, amelyet – bizonyos szabadságfokkal – a technológia és a mesterséges intelligencia alakít ki. Pohflepp utolsó munkája a Garage Digital program részeként megrendelésre készült a The Coming World; Ecology as the New Politics 2030–2100 [Egy eljövendő világ; ökológia mint új politika 2030–2100] című kiállításhoz, a Garage Kortárs Művészeti Múzeum számára. A Matthew Lutz-cal és Alessia Nigrettivel közösen fejlesztett digitális változat a Garage Digital weboldalán érhető el.

images:
  - SP.jpg

bio_en: 1978, Cologne (DE) – 2019, Berlin (DE)
bio_hu: 1978, Köln (DE) – 2019, Berlin (DE)

link: https://garage.digital/those-who/
video: 